import { Component, ElementRef, HostListener, Pipe, PipeTransform, Renderer2, ViewChild } from '@angular/core';
import { CommonModule, DatePipe, Location } from '@angular/common'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { ApplyRewardComponent } from '../apply-reward/apply-reward.component';
import { LoginComponent } from '../login/login.component';
// import { SignupComponent } from 'src/app/authentication/signup/signup.component';
import { RemarksDialogComponent, DialogData } from '../remarks-dialog/remarks-dialog.component';
import { DeleteConfirmation } from '../header/header.component';
import { OrderScheduleComponent } from '../order-schedule/order-schedule.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Cart, CartItem } from '../../models/cart.model';
import { CartService } from '../../services/cart/cart.service';
import { StorageService } from '../../services/storage/storage.service';
import { ERROR_CODE_OUT_OF_STOCK, PRODUCT_IMAGE_FOLDER, SESSION_AUTHENTICATED_CUSTOMER, SESSION_CART, SESSION_DEPARTMENT_LIST, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_PICKUP_LOCATION_LIST, SESSION_APPLIED_REWARDS, SESSION_SALE_ITEM_LIST, SESSION_OFFERS } from '../../app.constants';
import { ItemListService } from '../../services/shared/item-list.service';
import { Customer } from '../../models/customer.model';
import { ShopService } from '../recent-shops/shop.service';
import { TimeService } from '../../services/shared/time-service.service';
import { DateSelectionService } from '../../services/shared/date-selection.service';
import { SettingsService } from '../../services/shared/settings.service';
import { environment } from '../../environments/environment';
import { RewardsComponent } from '../rewards/rewards.component';
import { FormsModule, FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NotificationService } from '../../services/shared/notifications.service';
import { SignupComponent } from '../signup/signup.component';
import moment from 'moment';
import { DateTimeServiceService } from '../../services/shared/date-time-service.service';
import { UtilService } from '../../services/shared/util.service';
import { ConfirmDialogModel, MessageDialogService, NotificationDialogModel } from '../../services/shared/message-dialog.service';
import { reload } from 'firebase/auth';
import { CartPanelComponent } from './components/cart-panel/cart-panel.component';
import { RewardCardComponent } from "../rewards/components/reward-card/reward-card.component";
import { RewardService } from '../../services/shared/reward.service';
import { GtmService } from '../../services/shared/gtm.service';
// import * as _ from 'lodash';


export interface Title {
  value: string;
  display: string;
}

@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  templateUrl: './shopping-cart.component.html',
  styleUrl: './shopping-cart.component.scss',
  imports: [MatExpansionModule, RouterModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    MatFormFieldModule, RouterModule, CartPanelComponent, RewardCardComponent]
})
export class ShoppingCartComponent {
  @ViewChild('firstName') firstName: any;
  tempI: any = 1;
  shopAccordianTitle: any;
  showIcon: boolean = false;
  cart: Cart;
  selectedCustomer!: Customer;
  shopName: any;
  validShop: any;
  cartItemInfo: any = [];
  allSaleItemList: any = [];
  selectedService: any;
  displaytitle: any;
  titles: Title[] = [
    { value: 'Mr.', display: 'Mr.' },
    { value: 'Ms.', display: 'Ms.' },
    { value: 'Mrs.', display: 'Mrs.' }
  ];
  shopWorkingInfo: any;
  customerName: any;
  isCustomerTabOpen: any;
  serviceName: any;
  systemSettings: any;
  currencySymbol: any;
  saleItemInfo: any;
  imageUrl: any;
  uploadUrl: any;
  cartItem: any;
  offerAmount: any;
  formControl: any;
  isContinueOptioniVisible = false;
  validEmail: any;
  shopPickupLocation: any = [];
  selectedPickUplocation: any;
  confirmationService: any;
  showServiceIcon: any;
  showCustomerIcon: any;
  preparationTime: any;
  selectedShopDeptDtl: any;
  departmentList: any;
  workinghours: any;
  itemCategories: any;
  step: any;
  msg: any;
  appliedOffers: any;
  hasImageError: boolean = false;
  crmAssetsUrl = environment.crm.assetsUrl;
  crmOffers: any;
  items = [
    { name: 'Apple', price: 10, inStock: true },
    { name: 'Banana', price: 5, inStock: false },
    { name: 'Cherry', price: 15, inStock: true },
  ];


  @ViewChild('orderDetailTitleDiv', { static: true }) orderDetailTitleDiv!: ElementRef;
  @ViewChild('cartSummaryDiv', { static: true }) cartSummaryDiv!: ElementRef;
  @ViewChild('proceedButton', { static: true }) proceedButton!: ElementRef;
  isScrolledToTop = false;
  isSticky: boolean = false;
  proceedButtonInitRect: any;
  isProceedBtnVisible: boolean = false;
  isDetailDiVisible: boolean = true;
  appliedRewards: any = [];
  selectedDefault: any;
  isDefault: boolean = false;
  cartProceedStatus: number=0;

  constructor(private location: Location,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public cartService: CartService,
    private storageService: StorageService,
    private itemListService: ItemListService,
    private shopDetailService: ShopService,
    private timeService: TimeService,
    private dateSelectionService: DateSelectionService,
    private datePipe: DatePipe,
    private settingsService: SettingsService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private dateTimeServiceService: DateTimeServiceService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private messageDialogService: MessageDialogService,
    private rewardService: RewardService,
    private gtmService: GtmService) {

    this.uploadUrl = environment.oos.assetsUrl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    // this.initTitleBarParams();
    this.departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
    this.itemCategories = JSON.parse(this.storageService.loadFromSession(SESSION_ITEM_CATEGORY_LIST));
    this.crmOffers = JSON.parse(this.storageService.loadFromSession(SESSION_OFFERS));
    this.isCustomerTabOpen = false;
    this.cart = this.loadCart();
    this.getShopPickupLocation();
    this.selectedShopDeptDtl = this.departmentList.filter((e: any) =>
      this.cart.shop.department.find((csd: any) => csd === e.id && e.isCommon === 0)
    );
    this.workinghours = this.cart.shop.shopDailyWorkingHours;
    this.formControl = {
      email: new FormControl('', [Validators.required, Validators.email]),
      title: new FormControl(),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(),
      phoneNumber: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(9)])
    };
  }

  ngOnInit() {
    this.utilService.scrollToTop();
    this.notificationServices();
    // this.menuService.isVertical = true;
    this.showIcon = true;
    this.showServiceIcon = true;
    this.showCustomerIcon = true;
    let selectedDateFt = moment(this.cart.deliveryDate.date).format('YYYY-MM-DD');
    this.onShopDetailTabClose('');
    this.preValidateCartItems();
    let inputParam = { "shop_id": this.cart.shop.id, "pickUp_date_time": selectedDateFt + " " + this.cart.deliveryTime };
    this.dateTimeServiceService.getOutofStockByShop(inputParam);
    this.appliedRewards = this.rewardService.getAppliedRewards();
  }

  /** to prevent typing charaters other than numbers  */
  preventNonNumeric(event: KeyboardEvent) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }
  /**
   * Observer the ui scrol changes
   */
  setViewObserver(): void {
    // const proceedBtnObserver = new IntersectionObserver((entries) => {
    //   const entry = entries[0];
    //   if (entry.isIntersecting) {
    //     this.isProceedBtnVisible = true;
    //     const divTop=-(this.cartSummaryDiv.nativeElement.offsetTop+this.proceedButton.nativeElement.offsetHeight);
    //     this.renderer.setStyle(this.cartSummaryDiv.nativeElement, 'top', `${divTop}px`);
    //     const clienthWidth = this.cartSummaryDiv.nativeElement.clientWidth;
    //     const clientLeft = this.cartSummaryDiv.nativeElement.offsetLeft;
    //     this.renderer.setStyle(this.cartSummaryDiv.nativeElement, 'max-width', `${clienthWidth}px`);
    //     this.renderer.setStyle(this.cartSummaryDiv.nativeElement, 'left', `${clientLeft}px`);
    //   } else {
    //     this.isProceedBtnVisible = false;
    //     this.renderer.setStyle(this.cartSummaryDiv.nativeElement, 'max-width', 'unset');
    //     this.renderer.setStyle(this.cartSummaryDiv.nativeElement, 'left', 'unset');
    //   }
    // });
    // proceedBtnObserver.observe(this.proceedButton.nativeElement);

    // const options = {
    //   root: null, // Observe relative to viewport
    //   threshold: 0.1 // Check if 10% of the div is visible
    // };
    // const orderDtlDivObserver = new IntersectionObserver((entries) => {
    //   const entry = entries[0];
    //   if (entry.isIntersecting) {
    //     this.isDetailDiVisible = true;

    //   } else {
    //     this.isDetailDiVisible = false;
    //   }
    // });
    // orderDtlDivObserver.observe(this.orderDetailTitleDiv.nativeElement);

  }

  ngAfterViewInit(): void {

    // this.proceedButtonInitRect = this.proceedButton.nativeElement.getBoundingClientRect();

    this.setViewObserver();
  }
  notificationServices() {
    this.notificationService.userLoginChanged.subscribe(status => {
      if (status == 'in') {
        this.loadCart();
      }
    });
    this.notificationService.selectDate.subscribe(date => {
      if (date !== null && date !== undefined) {
        this.loadCart();
      }
    });
    this.notificationService.selectTime.subscribe(time => {
      if (time !== null && time !== undefined) {
        this.loadCart();
      }
    });
    this.notificationService.rewardApplied.subscribe(reward => {
      if (reward !== null && reward !== undefined) {
        this.loadCart();
      }
    });
    this.notificationService.rewardRemoved.subscribe(reward => {
      if (reward !== null && reward !== undefined) {
        this.loadCart();
      }
    });
    this.notificationService.cartChanged.subscribe(cart => {
      if (cart !== null && cart !== undefined) {
        this.loadCart();
      }
    });
  }
  /**
   * Load cart data
   */
  loadCart(): Cart {
    this.cart = this.cartService.getCustomerCart();
    this.appliedOffers = [];
    // JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));
    this.cartItemInfo = this.cart.items;
    const data = this.sortByItemNotAvailable();
    this.offerAmount = 0;
    for (const item of this.cartItemInfo) {
      const totalOfferAmount = this.offerAmount + (item.offerAmount+item.customizationOfferAmount);
      this.offerAmount=Number((Math.round(totalOfferAmount * 100) / 100).toFixed(2));
      if (item.offers != undefined && item.offers.length > 0) {
        for (const offer of item.offers) {
          const crmOffer = this.crmOffers.find((of: any) => of.id == offer.offerId);
          if (crmOffer?.reward_eligibility == 2) {
            const itemOffer = { ...crmOffer, offerAmount: crmOffer.amount };
            this.appliedOffers.push(itemOffer);
          }
        }

      }
    }
    if (this.cart.businessType?.initialDelay != null && this.cart.businessType?.initialDelay != undefined) {
      this.preparationTime = this.cart.businessType.initialDelay;
    }
    this.cartItemExist();
    return this.cart;
  }

  sortByItemNotAvailable() {
    // this.cart.items?.forEach((item) => {
    //   console.log(item.isCustomized)
    // })

    return this.cart.items?.sort((a, b) => {
      if (a.hasError && !b.hasError) {
        return -1;
      } else if (!a.hasError && b.hasError) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  /**
  * If cart have item,then only checking various cart validations
  */
  cartItemExist() {

    if (this.cart.items?.length !== 0) {

      this.allSaleItemList = this.itemListService.saleItemList;
      this.selectedService = JSON.parse(this.storageService.loadFromSession(SESSION_CART)).service;
      if (this.cart.customer !== undefined) {
        this.selectedCustomer = this.cart.customer;
        // Object.assign(this.sessionStorage.get(SESSION_CART).customer);
      } else {
        this.selectedCustomer = <Customer>{};
        if (this.selectedCustomer.title !== null && this.selectedCustomer.title !== undefined) {
          this.displaytitle = this.titles[0].value;
        } else {
          this.displaytitle = '';
        }
        this.selectedCustomer.title = this.displaytitle;
        this.selectedCustomer.customerType = 2;
        this.cartService.setCustomer(this.selectedCustomer);
      }

      this.shopWorkingInfoChecking();
      this.shopName = this.cart.shop.name;
      if (this.selectedCustomer.firstName !== undefined) {
        this.customerName = this.getCustomerDisplayTitle();
      } else {
        this.customerName = 'Customer Detail (Guest)';
      }

      if (this.cart.deliveryDate !== undefined && this.cart.deliveryTime !== undefined) {
        this.serviceName = this.getServiceDisplayTitle();
      } else {
        // var dlgData: NotificationDialogModel = {
        //   message: `Select a pick up time from Date Time selection`,
        //   title: `Select Time`,
        //   yesButtonCaption: "Ok",
        // }
        // this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        //   if (result == 'Ok') {
        // const dlgData = { businessType: this.cart.businessType.code, shop: this.cart.shop, redirectUrl: "/shoppingCart", msg: "" }
        // this.dialog.open(OrderScheduleComponent, {
        //   panelClass: 'schedule-dialog',
        //   data: dlgData
        // });
        //   }
        // });
        // this.utilService.openDialog('Select Time', 'Select a pick up time from Date Time selection');
        // this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
      }

      this.systemSettings = this.settingsService.systemSettings;
      this.cartItemInfo = this.cart.items;
      if (this.systemSettings !== null && this.systemSettings !== undefined) {
        // this.currencySymbol = this.systemSettings.currency.symbol;
        this.currencySymbol = "$";
      }
    }
  }

  shopWorkingInfoChecking() {
    const searchParam = {
      'id': this.cart.shop.id, 'from': this.shopDetailService.formatDate(new Date(this.timeService.serverTime)),
      'to': this.shopDetailService.formatDate(new Date(this.timeService.serverTime))
    };
    this.dateSelectionService.getShopHoliday(searchParam).subscribe(
      res => {
        const shopHoliday = res.object;
        this.shopWorkingInfo = this.shopDetailService.getShopHoursByDate(this.cart.shop,
          new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime));
      });
  }

  getCustomerDisplayTitle(): string {
    if (this.selectedCustomer.title !== null && this.selectedCustomer.title !== undefined) {

      this.displaytitle = this.selectedCustomer.title;
    } else {
      this.displaytitle = '';
    }
    // return 'Customer (' + this.selectedCustomer.firstName + ', ' + this.selectedCustomer.phoneNumber + ', '
    //   + this.selectedCustomer.email + ')';
    return 'Customer (' + this.displaytitle + ' ' +
      this.selectedCustomer.firstName + ' ' +
      ((this.selectedCustomer.lastName !== null && this.selectedCustomer.lastName !== undefined) ? this.selectedCustomer.lastName : ' ') +
      ')';
  }

  /** Return formated service title */
  getServiceDisplayTitle(): string {
    return this.selectedService.name +
      ' (' + this.cart.deliveryDate.label + ' ' + this.datePipe.transform('1900-01-01T' + this.cart.deliveryTime, 'h:mm a') + ')';
  }
  /**
   *
   * @param info
   *on opening the shop detail tab the shop name(heading) is changed to shop detail
   */
  onShopDetailTabOpen(info: any) {
    this.shopAccordianTitle = 'Shop Details';
    this.showIcon = false;
  }

  /**
  *
  * @param info on closing the shop detail tab heading is changes to shop name
  */
  onShopDetailTabClose(info: any) {
    this.shopAccordianTitle = 'Shop:  ' + this.cart.shop.name;
    this.shopName = this.cart.shop.name;
    this.showIcon = true;
  }
  deleItemClickEvent(cartItem: any) {
    let saleItemData = this.allSaleItemList.find((asl: any) => asl.id === cartItem.ooSaleItemId);
    // this.updateGTMInfoForDelete('remove_from_cart', cartItem);
    var dlgData: ConfirmDialogModel = {
      message: `Do you want to delete ${saleItemData.name}?`,
      title: "Delete Item!"
    }

    var res = this.messageDialogService.showConfirmDialog(dlgData).subscribe(result => {
      if (result == "Yes") {
        this.cartService.deleteItem(cartItem);
        this.cartService.getValidPromotionalItemData();
        this.updateGTMInfoForDelete('remove_from_cart', cartItem);
        this.appliedRewards = this.appliedRewards.filter((rw: any) => rw.id !== cartItem.offerId);
        if (this.appliedRewards !== null && this.appliedRewards !== undefined) {
          this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
          // this.notificationService.notifyRewardRemoved(this.appliedRewards);
        }
        this.loadCart();
      }
    });

    // this.confirmationService.confirm({
    //   message: 'Do you want to delete ' + saleItemData.name + '?',
    //   accept: () => {
    //     // this.updateGTMInfoForDelete('remove_from_cart', cartItem);
    //     this.cartService.deleteItem(cartItem);
    //     this.cartService.getValidPromotionalItemData();
    //   },
    //   reject: () => {
    //     // cartItem.quantity = +cartItem.quantity;
    //   }
    // });
  }

  
  /**
   * GTM CODE STARTS HERE =>
   * @param event 
   * @param cartItem 
   */
  updateGTMInfoForDelete(event: string, cartItem: any): void {

    // this.gtmService.pushEvent(
    //   '',
    //   {
    //     userId: this.gtmService.getCustomer(),
    //     visitorType: this.gtmService.getVisitorType(),
    //     dateSelectd: this.gtmService.geDeliveryDate(),
    //     timeSelected: this.gtmService.getDeliveryTime(),
    //     preOrder: this.gtmService.isPreOrder()

    //   });

    // let items = [];
    // let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
    // items.push({
    //   item_id: saleItemInfo.id,
    //   item_name: saleItemInfo.name,
    //   affiliation: this.gtmService.getStore(),
    //   item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
    //   item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
    //   item_variant: '',
    //   price: cartItem.netTotal,
    //   quantity: cartItem.quantity

    // });
    // //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    // let customization = this.getSelectedCustomizations(cartItem);

    // if (customization.selectedCustomization.length > 0) {
    //   items.push(
    //     {
    //       item_id: saleItemInfo.id,
    //       item_name: saleItemInfo.name,
    //       item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
    //       item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
    //       item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
    //       item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
    //       price: customization.total * cartItem.quantity,
    //       quantity: cartItem.quantity
    //     }
    //   );
    // }

    // this.gtmService.pushG4AEvent(
    //   event,
    //   {
    //     ecommerce: {
    //       currency: 'NZD',
    //       value: cartItem.netTotal + cartItem.netCustomizationTotal,
    //       items: items
    //     }
    //   });
  }
  
  preValidateCartItems(): Boolean {
    let isValid = false;
    this.resetCartValidationError();

    if (!this.vaildateDepartment(true)) {
      isValid = false;
    }

    if (!this.isItemAvailableforSelectedDay(true)) {
      isValid = false;
    }

    if (!this.isCartItemInFilterdList(true)) {
      isValid = false;
    }
    // this.utilService.progressService.isLoading = false;

    return isValid;
  }

  resetCartValidationError(): void {
    let cartItem: any;
    for (cartItem of this.cart.items!) {
      cartItem.hasError = false;
      cartItem.errorMessage = '';
      cartItem.hasOutofStockError = false;
    }
  }

  isItemAvailableforSelectedDay(preValidation: any) {
    let isCartValid = true;
    // let visibleItem = true;
    // the cart item is not avaiable selected date, then no need to confirm.
    const cartItems = this.cart.items;
    let items;
    for (items of cartItems!) {

      const saleItemID = items.ooSaleItemId;
      const visibleItem = this.itemListService.isItemActiveByID(saleItemID);
      // let item = this.allSaleItemList.find(wh => wh.id === saleItemID);

      if (!visibleItem) {
        items.hasError = !visibleItem;
        items.errorMessage = 'This item is temporarily unavailable. Please remove this item from your order to continue.';
        isCartValid = false;
      }
    }
    if (isCartValid === false && !preValidation) {
      var dlgData: NotificationDialogModel = {
        message: `Your order contains items which are temporarily unavailable. Please remove the items from your order or select another shop.`,
        title: "Sorry",
        yesButtonCaption: "Ok",
        // noButtonCaption: "Select Shop"
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        if (result == "Select Shop") {
          this.changeShop();
        }
      });
    }
    return isCartValid;
  }

  isCartItemInFilterdList(preValidation: any) {
    let isCartItemInSaleItemList = true;
    const cartItems = this.cart.items;
    let filteredSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST));

    for (let cItem of this.cart.items!) {
      let isCartValid = filteredSaleItemList.some((e: any) => e.saleItemId === cItem.saleItemId);
      if (!isCartValid) {
        cItem.hasError = !isCartValid;
        cItem.errorMessage = 'This item is temporarily unavailable. Please remove this item from your order to continue.';
        isCartItemInSaleItemList = false;
      }
    }
    if (!isCartItemInSaleItemList) {
      isCartItemInSaleItemList = false;
      var dlgData: NotificationDialogModel = {
        message: `Your order contains items which are temporarily unavailable. Please remove the items from your order or select another shop.`,
        title: "Sorry",
        yesButtonCaption: "Ok",
        // noButtonCaption: "Select Shop",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        if (result == "Select Shop") {
          this.changeShop();
        }
      });
    }
    return isCartItemInSaleItemList;
  }
  /**
   * if the customer is a guest user,then fill the guest details.
   * If the guest is already registered customer redirect that customer to the login page
   */
  guestUser() {

    let isValidCustomer = false;
    if (this.isFormValid()) {

      if (this.selectedCustomer.phoneNumber != null && this.selectedCustomer.email !== null
        && this.selectedCustomer.firstName !== undefined && this.selectedCustomer.firstName !== null) {
        if (this.emailValidation(this.selectedCustomer.email)) {
          console.log("Email validation true");
          this.selectedCustomer.loginId = this.selectedCustomer.email;
          if (this.formControl.phoneNumber.errors !== null &&
            ((this.formControl.phoneNumber.errors.minlength.actualLength) <
              (this.formControl.phoneNumber.errors.minlength.requiredLength))) {
            var dlgData: NotificationDialogModel = {
              message: `Phone number should be 9 characters long.`,
              title: "Customer Info",
              yesButtonCaption: "Ok",
            }
            this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
            });
          } else {
            this.loginService.guestCustomer(this.selectedCustomer).subscribe(
              res => {
                if (res.success) {
                  isValidCustomer = true;
                  this.cartService.setCustomer(res.object);
                  this.storageService.saveToSession(SESSION_AUTHENTICATED_CUSTOMER, res.object);

                  this.updateGTMInfoForCheckout();
                  this.router.navigate(['/confirmOrders']);

                } else {
                  this.requestCustomerInfo();
                  var dlgData: NotificationDialogModel = {
                    message: res.message,
                    title: "Please login",
                    yesButtonCaption: "Ok",
                  }
                  this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
                    if (result == "Ok") {
                      const dialogRef = this.dialog.open(LoginComponent, {
                        panelClass: 'login-dialog'
                      });
                      dialogRef.afterClosed().subscribe((data) => {
                      });
                    }
                  });
                  // this.utilService.openDialog('Please login', res.message);
                  // this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/cart' } });

                }
              }
            );
          }
        } else {
          var dlgData: NotificationDialogModel = {
            message: 'Enter valid Email Address.',
            title: "Customer Info",
            yesButtonCaption: "Ok",
          }
          this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
          });
        }
      } else {
        this.requestCustomerInfo()
        this.isContinueOptioniVisible = true;
      }
    } else {
      this.isContinueOptioniVisible = true;

    }
    return isValidCustomer;
  }

  requestCustomerInfo() {
    var dialogRef = this.dialog.open(GustLoginConfirmation, {
      panelClass: 'confirm-dialog'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'login') {
        this.dialog.open(LoginComponent, {
          panelClass: 'login-dialog'
        });
      }
      else {
        this.step = 0
        return this.step;
      }
    })
  }
  hideContinueOptions() {
    this.isContinueOptioniVisible = false;
  }

  setGustLogin() {
    this.hideContinueOptions();
    this.isCustomerTabOpen = true;

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.firstName.nativeElement.focus();
    }, 1);
  }

  gotoRegisterAccount() {
    this.hideContinueOptions();
    this.router.navigate(['/sign-up'], { queryParams: { 'redirectUrl': '/shoppingCart' } });

  }

  /*GET pickup Location*/
  getShopPickupLocation() {
    let pickupLocation = JSON.parse(this.storageService.loadFromSession(SESSION_PICKUP_LOCATION_LIST));
    const selectedShop = this.cart.shop;
    if (pickupLocation != null && pickupLocation != undefined) {
      this.shopPickupLocation = pickupLocation.filter((so: any) => selectedShop.pickupLocation.findIndex((sc: any) => sc.ooPickupLocationId === so.id) >= 0);
      if (this.shopPickupLocation.length > 0) {
        const selectedDefault = selectedShop.pickupLocation.find((sco: any) => sco.isDefault === 1);
        let defaultLocation = this.shopPickupLocation.find((sco: any) => sco.id === selectedDefault.ooPickupLocationId);
        if (defaultLocation != null && defaultLocation != undefined) {
          this.selectedPickUplocation = defaultLocation;
        } else {
          this.selectedPickUplocation = pickupLocation[0];
          this.isDefault == false;
        }
      }
    }
  }

  // PIckup Location selection pass through this variable
  selectPickupLoc(selectedLocation: any) {
    this.selectedPickUplocation = selectedLocation;
  }

  setPickUpLocationToCart(selectedLocation: any) {
    this.selectedPickUplocation = selectedLocation;
    if (this.selectedPickUplocation !== undefined) {
      this.cartService.setPickupLocation(this.selectedPickUplocation.id);
      // this.cartService.setRemarks(this.selectedPickUplocation.description);
    }
  }

  editCustomer() {
    this.router.navigate(['/my-account'], { queryParams: { 'redirectUrl': '/shoppingCart' } });
  }

  changeShop() {
    this.router.navigate(['/shopList'], { queryParams: { 'redirectUrl': '/shoppingCart' } });
  }
  /**
   * reload the cart to reflect discount
   * @param cartItem 
   */
  onCartPanelChange(cartItem: any): void {
    this.loadCart();
  }

  updateGTMInfoForCheckout() {
    // const cartParam = this.cartService.getCartParam();


    // this.gtmService.pushEvent(
    //   '',
    //   {
    //     userId: this.gtmService.getCustomer(),
    //     visitorType: this.gtmService.getVisitorType(),
    //     dateSelectd: this.gtmService.geDeliveryDate(),
    //     timeSelected: this.gtmService.getDeliveryTime(),
    //     preOrder: this.gtmService.isPreOrder()

    //   });

    // let items = [];
    // for (const cartItem of cartParam.items) {
    //   let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
    //   items.push({
    //     item_id: saleItemInfo.id,
    //     item_name: saleItemInfo.name,
    //     affiliation: this.gtmService.getStore(),
    //     item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
    //     item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
    //     item_variant: '',
    //     price: cartItem.netTotal,
    //     quantity: cartItem.quantity

    //   });
    //   //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    //   let customization = this.getSelectedCustomizations(cartItem);

    //   if (customization.selectedCustomization.length > 0) {
    //     items.push(
    //       {
    //         item_id: saleItemInfo.id,
    //         item_name: saleItemInfo.name,
    //         item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
    //         item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
    //         item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
    //         item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
    //         price: customization.total * cartItem.quantity,
    //         quantity: cartItem.quantity
    //       }
    //     );
    //   }
    // }

    // this.gtmService.pushG4AEvent(
    //   'begin_checkout',
    //   {
    //     ecommerce: {
    //       currency: 'NZD',
    //       value:this. cartService.getCartTotal() ,
    //       items: items
    //     }
    //   });

  }

  /**
 * Extract the selected customizations
 * @returns 
 */
  getSelectedCustomizations(cartItem:any): any {
    let selectedCustomization = [];
    let custTotalPrice = 0;
    for (let cd of cartItem.customization) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1 && ov.isDefault == 0) {
          selectedCustomization.push(ov);
          custTotalPrice += ov.fixedPrice;
        }
      }
    }
    return { selectedCustomization: selectedCustomization, total: custTotalPrice };

  }
  /**
   * checking the mail validations
   */
  getEmailError(): any {

    return this.formControl.email.hasError('required') ? 'Please enter a valid email address.' :
      this.formControl.email.hasError('email') ? 'It is not a valid email address.' :
        '';

  }

  emailValidation(email: any) {
    this.validEmail = false;
    // tslint:disable-next-line:max-line-length
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    console.log(this.validEmail);
    return this.validEmail;
  }

  /**
   * check whether first name entered
   */
  getFirstNameError(): any {

    let error: any;
    if (this.formControl.firstName.hasError('required')) {
      error = 'Please enter your name';
    } else if (!this.formControl.firstName.touched) {
      error = 'Please enter your name';
    }
    return error;
  }

  getTitleError(): any {
    let error: any;
    if (this.formControl.title.hasError('required')) {
      error = 'Please enter your title';
    } else if (!this.formControl.title.touched) {
      error = 'Please enter your title';
    }
    return error;
  }

  /**
   * check whether the phone number  is valid or not
   */
  getPhoneNumberError(): any {
    let error: any;
    if (this.formControl.phoneNumber.hasError('required')) {
      error = 'Please enter a valid phone number.';
    } else if
      (this.formControl.phoneNumber.errors.maxlength !== undefined &&
      (this.formControl.phoneNumber.errors.maxlength.actualLength) > (this.formControl.phoneNumber.errors.maxlength.requiredLength)) {
      error = 'Phone number with in 13 characters length.';
    } else if (this.formControl.phoneNumber.errors.minlength !== undefined &&
      (this.formControl.phoneNumber.errors.minlength.actualLength) < (this.formControl.phoneNumber.errors.minlength.requiredLength)) {
      error = 'Phone number should be 9 characters long.';
    }
    return error;
  }

  /**
   * check whether is it a valid form
   */
  isFormValid(): boolean {
    const form = document.getElementById('guestForm');
    return (true);
  }

  /**
   * Enter key press prevented in cart order remarks.
   * ie, new line not allowed.
   * @param event 
   * @returns 
   */
  onKeydown(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }

  /**Dialog to accept the remarks from user */
  openDialog(): void {
    const dlgRemarks = this.dialog.open(RemarksDialogComponent, {
      maxWidth: '450px',
      width: '90%',
      // maxHeight: '300px',
      // height: '80%',
      data: { title: 'Special Instructions', remarks: this.cart.remarks }
    });

    dlgRemarks.afterClosed().subscribe(result => {
      const dlgResult: DialogData = result;
      if (!dlgResult.isCancelled) {
        this.cartService.setRemarks(dlgResult.remarks);
        // this.remarkButtonCaption = this.getRemarkButtonCaption();
      }
    });
  }

  back() {
    this.location.back()
  }

  itemListback() {
    this.router.navigate(['/shopItem']);
  }

  increment() {
    this.tempI++
  }

  decrement() {
    this.tempI--
  }

  rewardDialog() {
    this.dialog.open(RewardsComponent, {
      panelClass: 'reward-dialog'
    })
  }

  loginDialog() {
    this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    })
  }

  signupDialog() {
    this.dialog.open(SignupComponent, {
      panelClass: 'signup-dialog'
    })
  }

  deleteConfirmation() {
    this.dialog.open(DeleteConfirmation, {
      panelClass: 'confirm-dialog'
    })
  }

  scheduleDialog(buzType: any, shop: any) {
    this.notificationService.refreshAndOpenorderSchedulePopup();
    this.msg="";
    const dlgData = { businessType: buzType, shop: shop, redirectUrl: "/shoppingCart", msg: this.msg };
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data: dlgData
    });
  }
  /**Redirect to gust login page*/
  goToLoginPage() {
    this.hideContinueOptions();
    this.router.navigate(['/login'], { queryParams: { 'redirectUrl': '/shoppingCart' } });
  }
  proceed() {
    if (
      this.cartService.cart.items !== undefined &&
      this.cartService.cart.items.length > 0
    ) {
      if (!this.cartValidation()) {
        return;
      } else if (!this.vaildateDepartment(false)) {
        return;
      } else if (!this.isItemAvailableforSelectedDay(false)) {
        return;
      } else if (!this.isCartItemInFilterdList(false)) {
        return;
      } else if (this.cartService.isGuestLogin() && !this.guestUser()) {
        return;
        // this.dialog.open(GustLoginConfirmation,{
        //   panelClass:'confirm-dialog'
        // })
      } else {
        // this.router.navigate(['/confirmOrders']);
      }

      const cartParam = this.cartService.getCartParam();
      this.dateTimeServiceService.validateCart(cartParam).subscribe({
        next: (res: any) => {
          if (!res.success) {
            this.cartProceedStatus=1
            if (res.errorCode == ERROR_CODE_OUT_OF_STOCK) {
              if (res.object !== null) {
                let OutofStockCartItem = res.object.invalid_items;

                let cartItem: any;
                for (cartItem of this.cart.items!) {
                  OutofStockCartItem.forEach((e: any) => {
                    if (Number(e.id) == cartItem.ooSaleItemId) {
                      cartItem.hasError = true;
                      cartItem.hasOutofStockError = true;
                      cartItem.errorMessage = e.message;
                    }
                  });
                }
              }
              var dlgData: NotificationDialogModel = {
                message: res.message,
                title: "Information",
                yesButtonCaption: "Ok",
              }
              this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
                if (result == 'Ok') {
                  this.cartProceedStatus=0
                }
              });
              // this.utilService.openDialog('Information', res.message);
            }
          } else {
            this.cartProceedStatus=0
            this.updateGTMInfoForCheckout();
            this.router.navigate(['/confirmOrders']);
          }
        },
        error: (err: any) => {
        }
      });


    } else {
      var dlgData: NotificationDialogModel = {
        message: `There are no items in your order. Add items to your order.`,
        title: "Payment.",
        yesButtonCaption: "Ok",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
      });
      // this.utilService.openDialog(
      //   'Payment.',
      //   'There are no items in your order. Add items to your order.'
      // );
    }
  }

  cartValidation(): Boolean {
    let isCartValid = false;
    let maxAmountLimit = this.getMaxAmountLimit(this.cartService.cart);
    if ((this.cartService.getCartTotal() - this.offerAmount >=
      this.cartService.cart.businessType.amountMinLimit)) {
      if ((this.cartService.getCartTotal() - this.offerAmount <= maxAmountLimit)) {

        const dt = new Date(this.timeService.serverTime);
        const deliveryDate = this.cart.deliveryDate.date;
        const formttedDelivaryDate = new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime);
        const orderBefore = new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime);
        orderBefore.setMinutes(formttedDelivaryDate.getMinutes() - this.preparationTime);
        // const orderBefore = new Date((formttedDelivaryDate.setMinutes(formttedDelivaryDate.getMinutes() - this.preparationTime )));
        if (formttedDelivaryDate <= dt) {
          this.msg = 'The selected time has been expired.  We have set your earliest pickup time to '
          // this.utilService.openDialog('Time Expired.', 'The selected pickup time has expired. Please select another pickup time.');
          const dlgData = { businessType: this.cart.businessType.code, shop: this.cart.shop, redirectUrl: "/shoppingCart", msg: this.msg }
          this.dialog.open(OrderScheduleComponent, {
            panelClass: 'schedule-dialog',
            data: dlgData
          });
          // this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
        } else if (dt >= orderBefore) {
          // this.msg = 'We are sorry. We need at least ' + this.timeService.toHMFormat(this.preparationTime)+this.cart.deliveryTime
          //   + ' to prepare your order. Please select another pickup time.'
            this.msg='We are sorry. We need at least ' +this.preparationTime+' minutes to prepare your order. We have set your earliest pickup time to '
          // this.utilService.openDialog('We are sorry.', 'We need at least ' + this.timeService.toHMFormat(this.preparationTime)
          //   + ' to prepare your order. Please select another pickup time.');
          // this.cartService.cart.deliveryTime = undefined;
          const dlgData = { businessType: this.cart.businessType.code, shop: this.cart.shop, redirectUrl: "/shoppingCart", msg: this.msg }
          this.dialog.open(OrderScheduleComponent, {
            panelClass: 'schedule-dialog',
            data: dlgData
          });
          // this.router.navigate(['/datetime-selection'], { queryParams: { 'redirectUrl': '/cart' } });
        } else {
          // this.router.navigate(['/order-confirmation']);
          isCartValid = true;
        }
      } else {
        const city = (this.cartService.cart.shop.city) ? '\n' + this.cartService.cart.shop.city : '';
        const phoneNum = (this.cartService.cart.shop.phoneNumber) ? this.cartService.cart.shop.phoneNumber : '';
        const country = (this.cartService.cart.shop.country) ? ', ' + this.cartService.cart.shop.country : '';
        const msg = 'Thank you for ordering. The total order amount allowed for '
          + this.cart.businessType.name +
          ' is in the range of ' + this.currencySymbol +
          this.cartService.cart.businessType.amountMinLimit + ' and ' +
          this.currencySymbol + maxAmountLimit + //  this.cartService.cart.businessType.amountMaxLimit + // => changed as the amount limit is different for same day orders
          ' If you want a larger order, please call your selected store to make the order.' + '\n' + this.cartService.cart.shop.name +
          '\n' + this.cartService.cart.shop.address +
          city + country + '\n' + phoneNum;
        var dlgData: NotificationDialogModel = {
          message: msg,
          title: `Sorry!`,
          yesButtonCaption: "Ok",
        }
        this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => { });
        // this.utilService.openDialog
        //   ('Sorry!', 'Thank you for ordering. The total order amount for '
        //     + this.cart.businessType.name +
        //     ' is in the range of ' + this.currencySymbol +
        //     this.cartService.cart.businessType.amountMinLimit + ' and ' +
        //     this.currencySymbol + maxAmountLimit + //  this.cartService.cart.businessType.amountMaxLimit + // => changed as the amount limit is different for same day orders
        //     ' If you want a larger order, please call your selected store to make the order.' + '\n' + this.cartService.cart.shop.name +
        //     '\n' + this.cartService.cart.shop.address +
        //     city + country + '\n' +
        //     phoneNum);
      }
    } else {
      // const msg = 'Thank you for ordering. The total order amount for Order '
      //   + this.cart.businessType.name +
      //   ' is in the range of ' + this.currencySymbol +
      //   this.cartService.cart.businessType.amountMinLimit + ' and ' +
      //   this.currencySymbol + maxAmountLimit // this.cartService.cart.businessType.amountMaxLimit // => changed as the amount limit is different for same day orders
      //   + '.'
      const msg = 'Thank you for your order. Please ensure that the total order value falls between '
        + this.currencySymbol +
        this.cartService.cart.businessType.amountMinLimit + ' and ' +
        this.currencySymbol + maxAmountLimit // this.cartService.cart.businessType.amountMaxLimit // => changed as the amount limit is different for same day orders
        + '.'
      var dlgData: NotificationDialogModel = {
        message: msg,
        title: `Sorry!`,
        yesButtonCaption: "Ok",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => { });
      // this.utilService.openDialog
      //   ('Sorry!', 'Thank you for ordering. The total order amount for Order '
      //     + this.cart.businessType.name +
      //     ' is in the range of ' + this.currencySymbol +
      //     this.cartService.cart.businessType.amountMinLimit + ' and ' +
      //     this.currencySymbol + maxAmountLimit // this.cartService.cart.businessType.amountMaxLimit // => changed as the amount limit is different for same day orders
      //     + '.');

    }
    return isCartValid;
  }

  vaildateDepartment(preValidation: any) {
    this.selectedShopDeptDtl = this.departmentList.filter((e: any) =>
      this.cart.shop.department.find((csd: any) => csd === e.id && e.isCommon === 0)
    );

    let shopDepartmentValid = true;
    const tz = this.dateTimeServiceService.timezoneOffset();
    const formttedDelivaryDate = new Date(this.cart.deliveryDate.date + ' ' + this.cart.deliveryTime);
    const day = formttedDelivaryDate.getDay();
    const weekDay = (day === 0) ? 7 : day;
    // const workHours = this.workinghours.find(wh => wh.weekDay === weekDay);

    const workHours = this.workinghours.find((wh: any) => wh.date === this.datePipe.transform(this.cart.deliveryDate.date, "yyyy-MM-dd"));
    const deptWrkHoursList = workHours.deparmentHours;
    let errorMsg = '';

    for (const department of this.selectedShopDeptDtl) {
      shopDepartmentValid = true;
      const shopDepartmentHour = deptWrkHoursList.filter(function (obj: any) {
        return obj.departmentId === department.id;
      });
      if (shopDepartmentHour !== undefined && shopDepartmentHour !== null && shopDepartmentHour.length > 0) {

        const deliveryTime = this.datePipe.transform('1900-01-01T' + this.cart.deliveryTime, 'HH:mm:ss');

        if (!(((new Date('1970-01-01T' + deliveryTime + tz)) >= (new Date('1970-01-01T' + shopDepartmentHour[0].openTime + tz))) &&
          ((new Date('1970-01-01T' + deliveryTime + tz)) <= (new Date('1970-01-01T' + shopDepartmentHour[0].closeTime + tz))))) {

          shopDepartmentValid = !this.isItemInDepartment(department);
          if (!shopDepartmentValid) {
            var dptWrkHrs = shopDepartmentHour.filter
            errorMsg = 'Your order contains ' +
              department.name + ' item. ' + department.name +
              ' department is only ready for Pickup from ' + shopDepartmentHour[0].displayHours +
              ' for ' + this.cart.shop.name + ' on ' + this.cart.deliveryDate.label + '.' + ' Please choose a pick up time between '
              + shopDepartmentHour[0].displayHours +
              '  or remove ' + department.name + ' items from your order.';
            // var dlgData: NotificationDialogModel = {
            //   message: errorMsg,
            //   title: "Information..",
            //   yesButtonCaption: "Ok",
            // }
            // this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
            // });

          }
        }
      } else {
        shopDepartmentValid = !this.isItemInDepartment(department);
        if (!shopDepartmentValid) {
          errorMsg = 'Your order contains ' + department.name +
            ' item.' + department.name + ' department is closed at ' + this.cart.shop.name + ' on ' + this.cart.deliveryDate.label + '.' +
            ' Please choose another day or remove ' + department.name + ' items from your order.';
          var dlgData: NotificationDialogModel = {
            message: errorMsg,
            title: "Information",
            yesButtonCaption: "Ok",
          }
          this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
          });

        }
      }

      if (!shopDepartmentValid) {
        if (preValidation) {
          this.markDepartmentItemsAsInvalid(department);
        } else {
          var dlgData: NotificationDialogModel = {
            message: department.name + ' closed,' + errorMsg,
            title: "Closed department",
            yesButtonCaption: "Ok",
          }
          this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
          });
          // this.utilService.openDialog(department.name + ' closed', errorMsg);
          break;
        }
      }
    }
    return shopDepartmentValid;
  }

  isItemInDepartment(department: any): boolean {
    const cartItems = this.cart.items;
    const saleItems = this.allSaleItemList.filter((asl: any) =>
      cartItems?.find(ci => ci.ooSaleItemId === asl.id)
    );
    const itemCategories = this.itemCategories.filter((ic: any) => saleItems.find((si: any) => si.itemCategoryId === ic.id));
    const isAvailable = (itemCategories.findIndex((ic: any) => ic.departmentId === department.id) >= 0);
    return isAvailable;
  }

  markDepartmentItemsAsInvalid(department: any): void {

    let cartItem: any;

    for (cartItem of this.cart.items!) {

      const item = this.allSaleItemList.find((e: any) => e.id === cartItem.ooSaleItemId);
      const itemCat = this.itemCategories.find((e: any) => e.id === item.itemCategoryId);

      if (department.id === itemCat.departmentId) {
        cartItem.hasError = true;
        cartItem.errorMessage = 'Department ' + department.name + ' is closed at ' +
          this.cart.deliveryDate.label + ' ' + this.datePipe.transform('1900-01-01T' + this.cart.deliveryTime, 'h:mm a');
      }

    }

  }
  getMaxAmountLimit(cart: any) {
    // let valid=false;
    let curDate = moment(new Date()).format('YYYY/MM/DD');
    let amountLimit = 0;
    if (curDate === cart.deliveryDate.date) {
      amountLimit = cart.businessType.amountMaxLimitSameDay;
    } else {
      amountLimit = cart.businessType.amountMaxLimit;
    }
    // valid = ((this.cartService.getCartTotal() <= amountLimit));

    return amountLimit;
  }
  /**
   * For print 2 decimal point
   * @param str 
   * @param val 
   * @returns 
   */
  ParseFloat(str: any, val: any) {
    str = str.toString();
    // console.log(str.slice(0,str.indexOf(".")+val+1));
    if(str.indexOf(".")==-1){
      str=str+".00";
    }
    str = str.slice(0, (str.indexOf(".")) + val + 1); 
    return Number(str);  
  }

  shopSelection(shopId: any) {
    // if (this.selectedShop === undefined && this.storeSelectionService.selectedShop === undefined) {
    //   this.utilService.openDialog('Shop Selection.', 'No shop selected. Select a shop from the list.');
    // } else {
    //   if (this.cartService.cart.shop !== undefined && this.cartService.cart.shop.id !== this.selectedShop.id) {
    //     if (this.cartService.getTotalQuantity() === 0) {
    //       this.clearCart();
    //       this.setShop();
    //       this.gtmService.pushEvent(
    //         'page_engagement',
    //         {
    //           actionType: 'Select new store',
    //           storeSelected: this.gtmService.getStore(),  
    //           visitorType : this.gtmService.getVisitorType(),
    //           storeChanged: true 
    //         }
    //       );
    //     } else {
    //       this.confirmationService.confirm({
    //         message: 'Changing your pickup store will empty your order. Do you want to proceed?',
    //         accept: () => {
    //           this.clearCart();
    //           this.setShop();
    //           this.gtmService.pushEvent(
    //             'page_engagement',
    //             {
    //               actionType: 'Select new store',
    //               storeSelected: this.gtmService.getStore(),  
    //               visitorType : this.gtmService.getVisitorType(),
    //               storeChanged: true 
    //             }
    //           );
    //         },
    //         reject: () =>{
    //           // this.gtmService.pushEvent(
    //           //   'page_engagement',
    //           //   {
    //           //     actionType: 'Select new store',
    //           //     storeSelected: this.gtmService.getStore(),  
    //           //     visitorType : this.gtmService.getVisitorType(),
    //           //     storeChanged: false 
    //           //   }
    //           // );
    //         }
    //       });
    //     }
    //   }else{
    //     this.setShop();
    //     this.gtmService.pushEvent(
    //       'select store',
    //       {
    //         storeSelected: this.gtmService.getStore(),  
    //         visitorType : this.gtmService.getVisitorType()
    //       }
    //     );
    //   }
    // }
  }

  @ViewChild("rewardImg", { static: true })
  rewardImgRef!: ElementRef<HTMLImageElement>; // Reference to the image

  // ngAfterViewInit() {
  //   const image = this.rewardImgRef.nativeElement;
  //   console.log(image);
  //   image.onerror = () => {
  //     this.hasImageError = true;
  //   };
  // }
}

@Component({
  selector: 'gust-login',
  template: `
  <section>
    <div class="login-or-gust">
        <div class="glyphicon glyphicon-remove login-or-gust-close-button" (click)="hideContinueOptions()"></div>
            <span class="login-gust-container-title"></span>
              <div class="continue-option-container" style="align-items: center;padding-top:20px ;padding-bottom:20px;">
                <div>
                  <button class="btn btn-secondary py-2 px-4 col-lg-4 font-secondary mb-lg-2  custom-ms" type="button" (click)="close('login')" style="width: 205px;">SIGN
                      IN OR SIGN UP</button>
                </div>
                <div>
                  <button class="btn btn-primary py-2 px-4 col-lg-4 font-secondary mb-lg-2 mt-3  custom-ms" type="button"
                      (click)="close('guest')" style="width: 205px;">CONTINUE AS GUEST</button>
                </div>
              </div>
    </div>
  </section>`,

  styles: [`

  .custom-ms {
    margin-left: 4rem; 
  }

  @media (max-width: 400px) {
    .custom-ms {
      margin-left: 1.5rem; 
    }
  }
  
  ::ng-deep{
    .mdc-dialog__surface{ 
        flex-shrink: revert;
        
   }
   
}



  `]
})
export class GustLoginConfirmation {

  isContinueOptioniVisible = false;
  constructor(public dialogRef: MatDialogRef<DeleteConfirmation>, public router: Router) { }
  close(target: any) {
    this.dialogRef.close(target)
  }
  hideContinueOptions() {
    this.isContinueOptioniVisible = false;
  }



}

