<app-splash *ngIf="showSplash"></app-splash>
@if(this.showNotice()){
<app-shownotice [companyInfo]="this.companyInfo" (close)="closeNotice()"></app-shownotice>
}
<section class="banner-wrapper position-relative">
    <div id="bannerSlider" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
            <!-- @if (showTeaser()) {
            <div class="carousel-item active" data-bs-interval="5000">
                <img src="assets/images/banner/banner_teaser_26_30_sept.jpg" class="d-block w-100 banner-img" alt="...">
            </div>
            }
            @if (showGiveAway()) {
            <div class="carousel-item active" data-bs-interval="5000">
                <img src="assets/images/banner/banner_giveway_1_oct.jpg" class="d-block w-100 banner-img" alt="...">
            </div>
            } -->
            <div class="carousel-item active" data-bs-interval="5000">
                <img src="assets/images/banner/banner1.jpg" class="d-block w-100 banner-img" alt="...">
            </div>
            <div class="carousel-item" data-bs-interval="5000">
                <img src="assets/images/banner/banner2.jpg" class="d-block w-100 banner-img" alt="...">
            </div>
            <div class="carousel-item" data-bs-interval="5000">
                <img src="assets/images/banner/banner3.jpg" class="d-block w-100 banner-img" alt="...">
            </div>
        </div>
        <button class="carousel-control-prev opacity-75" type="button" data-bs-target="#bannerSlider"
            data-bs-slide="prev">
            <span class="bg-white rounded-circle" aria-hidden="true">
                <img src="assets/icons/left_arrow_icon.svg" alt="">
            </span>
        </button>
        <button class="carousel-control-next opacity-75" type="button" data-bs-target="#bannerSlider"
            data-bs-slide="next">
            <span class="bg-white rounded-circle" aria-hidden="true">
                <img src="assets/icons/right_arrow_icon.svg" alt="">
            </span>
        </button>
    </div>
    <div class="search-div position-absolute bottom-0 start-0 end-0 m-auto px-2 text-center">
        <div class="responsive-search-wrapper">
            <button class="btn-location btn btn-primary py-1 py-md-2 rounded-1 mb-0 mb-md-3"
                (click)="getCurrentLocation()">
                <img src="assets/icons/current_location_icon.svg" width="24" height="24" alt="">
                <span class="align-middle ps-0 ps-md-3">Use My Current Location</span>
                <img src="assets/icons/right_arrow_icon.svg" class="ps-3 ps-md-0" width="24" height="24" alt="">
            </button>
            <div class="search-wrapper bg-white rounded-1 border position-relative">
                <form>
                    <img src="assets/icons/search_icon.svg" width="44" alt=""
                        class="search-icon position-absolute ps-md-1 ps-lg-0 ms-2 ms-lg-4 my-auto top-0 bottom-0 start-0">
                    <div class="d-flex">
                        @if (!lastOrderShopName && !defaultShop){
                        <input type="search" class="border-0 w-100 fs-5 py-md-3 py-lg-4 pe-2 pe-md-4"
                            placeholder="Choose your favourite Shop" aria-label="Number" [formControl]="searchControl"
                            [matAutocomplete]="homeSearch">
                        }
                        @if (lastOrderShopName && !defaultShop) {
                        <input type="search" class="border-0 w-100 fs-5 py-md-3 py-lg-4 pe-2 pe-md-4"
                            placeholder="{{lastOrderShopName}}" aria-label="Number" [formControl]="searchControl"
                            [matAutocomplete]="homeSearch">
                        <a routerLink="shopDetail/{{lastOrderShopId}}" class="btn-search-go">
                            <i class="fa fa-chevron-circle-right" aria-hidden="true">
                            </i>
                        </a>
                        }
                        @if (defaultShop) {
                        <input type="search" class="border-0 w-100 fs-5 py-md-3 py-lg-4 pe-2 pe-md-4"
                            placeholder="{{defaultShop.name}}" aria-label="Number" [formControl]="searchControl"
                            [matAutocomplete]="homeSearch">
                        <a routerLink="shopDetail/{{defaultShop.id}}" class="btn-search-go">
                            <i class="fa fa-chevron-circle-right" aria-hidden="true">
                            </i>
                        </a>
                        }
                    </div>

                    <mat-autocomplete #homeSearch="matAutocomplete" class="home-search scrollbar"
                        panelMinWidth="auto">
                        @for (shop of filteredShopList; track shop.id) {
                        <mat-option class="mb-2 bg-white px-2 px-md-4 mx-md-2" [value]="shop.name" (onSelectionChange)="navigateToShopDetail(shop.id)">
                            <!-- <a routerLink="shopDetail/{{shop.id}}" class="go-button" style="color: black;"> -->
                                <div class="border border-1 p-2 p-md-3 rounded-1 mb-1 w-100 mx-md-1" style="padding-left: .25rem !important;
                                padding-right: .25rem !important;">
                                    <div>
                                    <div class="old-order d-inline-block align-top py-2 me-1">
                                        <img src="assets/icons/history_icon.svg" class="ms-2 me-2 me-md-4 history-icon"
                                            width="34" height="34" alt="">
                                    </div>
                                    <div class="d-inline-block shop-address">
                                        <p class="fs-5 fw-bold mb-2">{{shop.name}}</p>
                                        <h6 class="mb-0"><img src="assets/icons/location_icon.svg"
                                                class="align-middle me-2" width="15" height="15" alt=""> <span
                                                class="align-middle d-inline-block text-truncate"
                                                style="max-width: calc(100% - 26px);">{{shop.address}}</span></h6>
                                                <h6 class="mt-1">&nbsp;<i class="fa fa-phone me-2" aria-hidden="true"></i>{{shop.phoneNumber}}</h6>
                                                
                                        <!-- <button routerLink="shopDetail/{{shop.id}}"
                                            class="btn btn-primary rounded-pill font-secondary py-1 px-2 py-md-2 px-md-4 mt-3 mt-md-4">Start
                                            Ordering
                                        </button> -->
                                    </div>
                                </div>
                                <div class="d-flex font-secondary px-md-0 px-1" style="justify-content: left;">
                                    <button class="btn btn-dark rounded-pill btn-shadow py-2 me-2 text-nowrap" 
                                        (click)="scheduleDialog('CATERING',shop);$event.stopPropagation();">
                                        Order Catering</button>
                                    @if (isBentoShop(shop)) {
                                    <button class="btn btn-info rounded-pill btn-shadow py-2 me-md-2 text-truncate" style="min-width: 146.77px;"
                                        (click)="scheduleDialog('ORDERNOW',shop);$event.stopPropagation();">
                                        Order Sushi & Bento</button>
                                    }
                                    @else {
                                    <button class="btn btn-warning rounded-pill btn-shadow py-2 me-md-2 text-truncate" style="min-width: 146.77px;"
                                        (click)="scheduleDialog('ORDERNOW',shop);$event.stopPropagation();">
                                        Order sushi</button>
                                    }      
                                </div>
                                </div>
                            <!-- </a> -->
                        </mat-option>
                        }
                    </mat-autocomplete>
                </form>
            </div>
        </div>
    </div>
</section>
@if(lastOrderShopName && !defaultShop){
<p class="note-strip mb-0 text-center bg-secondary text-white px-3">
    Based on your previous order history we have set <span role="button"
        class="text-primary">{{lastOrderShopName}}</span> shop as default.
</p>
}
@if(defaultShop) {
<div class="note-strip mb-0 text-center bg-secondary text-white px-3 d-flex justify-content-center align-items-center">
    <div>
        <img src="assets/icons/shop-01.svg" width="24" class="" alt="" style="filter: invert(1);">
    </div>

    <div class="mx-4 shopDetails">
        <p style="margin-bottom: 0%;"><span role="button"
            class="text-primary">{{defaultShop.name}}&nbsp;</span>
        </p>
        <p style="margin-bottom: 0%;">
            <span>{{deliveryMethod.name}} {{ deliveryDate.label}} {{deliveryDateTime |
                date:'shortTime'}}</span>
        </p>
    </div>

    <div>
        <span>
            <button class="btn btn-outline-dark rounded-pill font-secondary px-1 px-sm-2 py-1 ms-1 ms-sm-0"
                (click)="navigateToshopList()">
                <img src="assets/icons/edit_icon.svg" width="24" class="white-icon" alt="" style="filter: invert(1);">
            </button>
        </span>
    </div>
    <!-- <p style="margin-bottom: 0%;"><span role="button"
    class="text-primary">{{defaultShop.name}}</span></p>
    <img src="assets/icons/shop-01.svg" width="24" class="me-5" alt="" style="filter: invert(1);">
    <span class="align-middle">{{deliveryMethod.name}} {{ deliveryDate.label}} {{deliveryDateTime |
        date:'shortTime'}}</span>
    <span class="col-auto ms-5 ms-lg-4">
        <button class="btn btn-outline-dark rounded-pill font-secondary px-1 px-sm-2 py-1 ms-1 ms-sm-0"
            (click)="navigateToshopList()">
            <img src="assets/icons/edit_icon.svg" width="24" class="white-icon" alt="" style="filter: invert(1);">
        </button>
    </span> -->
</div>
}
<section class="special-offers container container-2">
    @if(rewardsData){
    @if (isRewardsAvailable) {
    <h2 class="font-secondary text-center">Rewards waiting for you</h2>
    <h6 class="text-center">*Rewards can be chosen and applied within the shopping cart.</h6>
    <owl-carousel-o [options]="orderSlides" class="home-slides">
        @for (reward of rewardsData; track $index) {
        <ng-template carouselSlide>
            <div class="owl-item-container">
                <app-promotion-card [promotion]="reward"></app-promotion-card>
            </div>
        </ng-template>
    
        }
    </owl-carousel-o>
    }
    }
    @else {
        <h2 class="font-secondary text-center">Rewards waiting for you</h2>
        <h6 class="text-center">For our returning customers, please log in to enjoy your rewards. If you're new, register to
            unlock exclusive benefits.</h6>
    }
</section>



@if(canShowOffers(offersData)){
<section class="special-offers container container-2">
    <h2 class="font-secondary text-center">SPECIAL OFFERS</h2>
    <!-- <h6 class="text-center">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.</h6> -->
    <owl-carousel-o [options]="orderSlides" class="home-slides">
        @for (offer of offersData; track $index) {
        @if (offer.show_in_offers==1 && ((customerInfo != undefined && offer.reward_eligibility == 1)|| (customerInfo == undefined))) {
        <ng-template carouselSlide>
            <div class="owl-item-container">
                <app-promotion-card [promotion]="offer"></app-promotion-card>
            </div>
        </ng-template>
        }
        }
    </owl-carousel-o>
</section>
}

<section class="special-offers container container-2">
    @if(recentOrderList!=null){
    <h2 class="font-secondary text-center">Hey! {{customerName}}, We Care About Your Recent Orders</h2>
    }
    @if(recentOrderList!=null){
    <div
        class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5 home-row justify-content-start justify-content-xxl-start">
        @for (item of recentOrderList; track $index) {
        <div class="col">
            <div class="card text-sm-center p-3 p-lg-4">
                <img src="{{oosAssetsUrl}}/images/product/{{item.image}}" width="150" height="150"
                    class="w-auto m-sm-auto" alt="">
                <div class="card-body-sm">
                    <h5 class="mb-2 mb-lg-1 d-flex justify-content-sm-center">
                        <span class="text-truncate">{{item.shopName}}</span>
                    </h5>
                    <p class="mb-3 mb-lg-5">{{item.orderDate}}</p>
                    <div class="d-flex justify-content-between font-secondary">
                        <h5 class="my-auto me-sm-2 amount">${{item.totalAmount|number:"1.2-2"}}</h5>
                        <button class="btn btn-primary rounded-pill py-1 py-sm-2 px-sm-4 text-truncate"
                            (click)="orderDetails(item.orderId)">REORDER</button>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    }
    <!-- @else{
    <h6 class="text-center">You have no recent orders to fullfill.!</h6>
    } -->
    @if (customerInfo==null || customerInfo==undefined) {
    <h6 class="text-center">Get instant access to your past orders - sign in or create an account today!</h6>
    }
</section>
<footer class="text-center">
    <p class="text-decoration-underline" role="button" routerLink="/termsAndConditions">Terms & Conditions and Policies
    </p>
    <p class="text-decoration-underline" role="button" (click)="showBuildNo=true;">(C) 2024 Mochi Digital Ltd</p>
    @if(showBuildNo){
    <p class="text-decoration-underline" role="button">Build No:{{buildNo}}</p>
    }
</footer>
